<template>
  <g>
    <svg:style>
      .cls-10,
      .cls-11,
      .cls-12,
      .cls-13,
      .cls-14,
      .cls-15,
      .cls-16,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke:#aeaeae;
        stroke-width:0.25px;
      }
      .cls-10,
      .cls-11,
      .cls-12,
      .cls-13,
      .cls-14,
      .cls-15,
      .cls-16,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke-linecap:round;
        stroke-linejoin:round;
      }
      .cls-4 {fill:url(#inox-gradient);}
      .cls-5 {fill:url(#inox-gradient-2);}
      .cls-6 {fill:url(#inox-gradient-3);}
      .cls-7 {fill:url(#inox-gradient-4);}
      .cls-8 {fill:url(#inox-gradient-5);}
      .cls-9 {fill:url(#inox-gradient-6);}
      .cls-10 {fill:url(#inox-gradient-7);}
      .cls-11 {fill:url(#inox-gradient-8);}
      .cls-12 {fill:url(#inox-gradient-9);}

      .cls-13 {fill:url(#glass-pattern);}
      .cls-14 {fill:url(#glass-pattern);}
      .cls-15 {fill:url(#glass-pattern);}
      .cls-16 {fill:url(#glass-pattern);}
    </svg:style>

    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth + 11.33"
                    :y1="doorTopHeight1 + 150.96"
                    :x2="doorLeftWidth + 17.01"
                    :y2="doorTopHeight1 + 150.96"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>

    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth + 38.44"
                    :y1="doorTopHeight1 + 151.32"
                    :x2="doorLeftWidth + 40.25"
                    :y2="doorTopHeight1 + 151.32"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-2"
                    :x1="doorLeftWidth + 70.93"
                    :y1="doorTopHeight1 + 72.69"
                    :x2="doorLeftWidth + 119.67"
                    :y2="doorTopHeight1 + 72.69"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-3"
                    :x1="doorLeftWidth + 70.93"
                    :y1="doorTopHeight1 + 125.04"
                    :x2="doorLeftWidth + 119.67"
                    :y2="doorTopHeight1 + 125.04"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-4"
                    :x1="doorLeftWidth + 70.93"
                    :y1="doorTopHeight1 + 177.56"
                    :x2="doorLeftWidth + 119.67"
                    :y2="doorTopHeight1 + 177.56"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-5"
                    :x1="doorLeftWidth + 70.93"
                    :y1="doorTopHeight1 + 229.57"
                    :x2="doorLeftWidth + 119.67"
                    :y2="doorTopHeight1 + 229.57"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-6"
                    :x1="doorLeftWidth + 33.39"
                    :y1="doorTopHeight1 + 72.52"
                    :x2="doorLeftWidth + 75.99"
                    :y2="doorTopHeight1 + 72.52"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-7"
                    :x1="doorLeftWidth + 33.39"
                    :y1="doorTopHeight1 + 125.01"
                    :x2="doorLeftWidth + 75.99"
                    :y2="doorTopHeight1 + 125.01"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-8"
                    :x1="doorLeftWidth + 33.39"
                    :y1="doorTopHeight1 + 177.67"
                    :x2="doorLeftWidth + 75.99"
                    :y2="doorTopHeight1 + 177.67"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-9"
                    :x1="doorLeftWidth + 33.39"
                    :y1="doorTopHeight1 + 230.15"
                    :x2="doorLeftWidth + 75.99"
                    :y2="doorTopHeight1 + 230.15"
                    xlink:href="#handle-gradient"/>

    <g id="S24">
      <!-- <g v-if="showVariant === 'outside' && showInox"> -->
      <g v-if="showInox">
        <rect id="inox"
              class="cls-4"
              :x="doorLeftWidth + 38.44"
              :y="inoxY"
              width="1.81"
              :height="inoxH"/>

        <rect id="inox-2"
              data-name="inox"
              class="cls-5"
              :x="doorLeftWidth + 70.93"
              :y="doorTopHeight1 + 71.78"
              :width="inoxW"
              height="1.81"/>

        <rect id="inox-3"
              data-name="inox"
              class="cls-6"
              :x="doorLeftWidth + 70.93"
              :y="doorTopHeight1 + 124.14"
              :width="inoxW"
              height="1.81"/>

        <rect id="inox-4"
              data-name="inox"
              class="cls-7"
              :x="doorLeftWidth + 70.93"
              :y="doorTopHeight1 + 176.63"
              :width="inoxW"
              height="1.87"/>

        <rect id="inox-5"
              data-name="inox"
              class="cls-8"
              :x="doorLeftWidth + 70.93"
              :y="doorTopHeight1 + 228.63"
              :width="inoxW"
              height="1.87"/>
      </g>

      <!-- <g v-if="showVariant === 'inside' && showInox">
        <rect id="inner-inox"
              class="cls-9"
              :x="doorLeftWidth + 33.39"
              :y="doorTopHeight1 + 51.22"
              width="42.6"
              height="42.6"/>

        <rect id="inner-inox-2"
              data-name="inner-inox"
              class="cls-10"
              :x="doorLeftWidth + 33.39"
              :y="doorTopHeight1 + 103.71"
              width="42.6"
              height="42.6"/>

        <rect id="inner-inox-3"
              data-name="inner-inox"
              class="cls-11"
              :x="doorLeftWidth + 33.39"
              :y="doorTopHeight1 + 156.4"
              width="42.6"
              height="42.53"/>

        <rect id="inner-inox-4"
              data-name="inner-inox"
              class="cls-12"
              :x="doorLeftWidth + 33.39"
              :y="doorTopHeight1 + 208.89"
              width="42.6"
              height="42.53"/>
      </g> -->

      <rect id="glass"
            class="cls-13"
            :x="doorLeftWidth + 38.44"
            :y="doorTopHeight1 + 56.27"
            width="32.49"
            height="32.49"/>

      <rect id="glass-2"
            data-name="glass"
            class="cls-14"
            :x="doorLeftWidth + 38.44"
            :y="doorTopHeight1 + 108.76"
            width="32.49"
            height="32.49"/>

      <rect id="glass-3"
            data-name="glass"
            class="cls-15"
            :x="doorLeftWidth + 38.44"
            :y="doorTopHeight1 + 213.63"
            width="32.49"
            height="32.56"/>

      <rect id="glass-4"
            data-name="glass"
            class="cls-16"
            :x="doorLeftWidth + 38.44"
            :y="doorTopHeight1 + 161.28"
            width="32.49"
            height="32.56"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
    'showVariant',
    'laminationId',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
    }
  },
  computed: {
    inoxH() {
      return this.showBg ?
        (this.doorHeight - this.inoxOffset * 2) :
        (this.doorHeight - this.leafOffset * 2)
    },
    inoxY() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    inoxW() {
      return this.showBg ?
        this.doorWidth - this.inoxOffset - 70.93 :
        this.doorWidth - this.leafOffset - 70.93
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    }
  },
}
</script>
